import { cva, type VariantProps } from '@mentimeter/ragnar-tailwind-config';

export const textInputVariant = cva(
  'absolute items-center justify-center h-full text',
  {
    variants: {
      iconAction: { true: 'pointer-events-auto', false: 'pointer-events-none' },
      iconType: {
        leading: 'left-0',
        trailing: 'right-0',
      },
      inputSize: {
        compact: '',
        default: '',
      },
    },
    compoundVariants: [
      {
        iconType: 'leading',
        inputSize: 'default',
        iconAction: true,
        className: 'pl-2',
      },
      {
        iconType: 'leading',
        inputSize: 'default',
        iconAction: false,
        className: 'pl-4',
      },
      {
        iconType: 'leading',
        inputSize: 'compact',
        iconAction: true,
        className: 'pl-1',
      },
      {
        iconType: 'leading',
        inputSize: 'compact',
        iconAction: false,
        className: 'pl-3',
      },
      {
        iconType: 'trailing',
        inputSize: 'default',
        iconAction: true,
        className: 'pr-2',
      },
      {
        iconType: 'trailing',
        inputSize: 'default',
        iconAction: false,
        className: 'pr-4',
      },
      {
        iconType: 'trailing',
        inputSize: 'compact',
        iconAction: true,
        className: 'pr-1',
      },
      {
        iconType: 'trailing',
        inputSize: 'compact',
        iconAction: false,
        className: 'pr-3',
      },
    ],
  },
);

export type TextInputIconVariantProps = VariantProps<typeof textInputVariant>;

import React, { type JSX } from 'react';
import { Box, type BoxT } from '../../Box';
import { IconButton } from '../../IconButton';
import type { InputVariantProps } from '../utils';
import {
  textInputVariant,
  type TextInputIconVariantProps,
} from './TextInputIcon.variants';

interface TextInputIcon
  extends BoxT,
    Pick<TextInputIconVariantProps, 'iconType'> {
  icon: JSX.Element;
  iconLabel?: string | undefined;
  iconAction?: (() => void) | undefined;
  inputSize?: InputVariantProps['size'];
}

export const TextInputIcon = ({
  icon,
  iconLabel = undefined,
  iconAction = undefined,
  inputSize,
  iconType,
  ...rest
}: TextInputIcon) => {
  if (!icon) return;

  return (
    <Box
      className={textInputVariant({
        iconAction: Boolean(iconAction),
        inputSize,
        iconType,
      })}
      aria-hidden={!iconAction}
      {...rest}
    >
      {iconAction ? (
        <IconButton
          aria-label={iconLabel}
          onClick={iconAction}
          variant="secondary"
          size="compact"
        >
          {icon}
        </IconButton>
      ) : (
        icon
      )}
    </Box>
  );
};

import React, { forwardRef } from 'react';
import type { KosmosMarginST } from '@mentimeter/ragnar-styled';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { Clickable, type ClickableT } from '../Clickable';
import { type RagnarButtonState } from '../Button';
import { IconContainer } from './IconContainer';
import { variants } from './IconButtonGeneric.variants';

export type IconButtonVariants =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'primaryReverse'
  | 'outline'
  | 'negativeWeak';

export type IconButtonToggleVariants = Omit<IconButtonVariants, 'negativeWeak'>;

export interface IconButtonGenericBaseProps extends KosmosMarginST, ClickableT {
  state?: RagnarButtonState | undefined;
  borderRadius?: 'full' | 'medium' | undefined;
  size?: 'large' | 'default' | 'compact' | undefined;
  checked?: boolean | undefined;
}

export interface IconButtonProps extends IconButtonGenericBaseProps {
  variant?: IconButtonVariants | undefined;
}

export interface IconButtonToggleProps
  extends Omit<IconButtonGenericBaseProps, 'href' | 'target'> {
  variant?: IconButtonToggleVariants | undefined;
}

type IconButtonGenericProps = IconButtonProps | IconButtonToggleProps;

export const IconButtonGeneric = forwardRef<
  HTMLElement,
  IconButtonGenericProps
>(
  (
    {
      children,
      borderRadius = 'medium',
      size = 'default',
      variant = 'primary',
      onClick,
      checked,
      disabled = false,
      state,
      className,
      ...rest
    }: IconButtonGenericProps,
    forwardedRef,
  ) => {
    const hasState = Boolean(state);
    const isPressed = Boolean(checked);
    const iconButtonClasses = variants({
      // Fixes TS error by allowing all variants, in reality only a subset is allowed due to IconButtonGenericProps
      variant: (variant as IconButtonVariants) || 'primary',
      borderRadius,
      size,
    });

    return (
      <Clickable
        ref={forwardedRef}
        type="button"
        disabled={hasState || disabled}
        onClick={onClick}
        {...rest}
        {...(isPressed && {
          'aria-pressed': isPressed,
        })}
        data-has-state={hasState}
        className={clsx(iconButtonClasses, className)}
      >
        <IconContainer state={state} size={size}>
          {children}
        </IconContainer>
      </Clickable>
    );
  },
);
